import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["aperture"]

  connect() {
    const { width, height } = window.screen
    this.apertureTarget.value = `${width}${height}`
  }
}
