import { Controller } from "stimulus"
import { getCookie, setCookie } from "lib/cookie"

export default class extends Controller {
  static targets = ['overlay', 'dialog']

  connect() {
    this.show()
  }

  show() {
    this.overlayTarget.classList.remove('hidden')
  }

  close() {
    this.overlayTarget.classList.add('hidden')
  }

  closeOnOutbound() {
    if (this.dialogTarget.contains(event.target)) {
      return
    }

    this.close()
  }
}
