import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["impressions"]

  scrollRight() {
    if (this.impressionsTarget.scrollLeft >= this.scrollableWidth) {
      this.impressionsTarget.scrollLeft = 0
    } else {
      this.impressionsTarget.scrollLeft += 450
    }
    event.preventDefault()
  }

  get scrollableWidth() {
    return (this.impressionsTarget.scrollWidth - this.impressionsTarget.clientWidth)
  }
}
